<template>
  <div class="stack">
    <div class="stack-header">
      <tabs :list="nav" class="medium-title">
        <template #item="{ item }">
          <router-link active-class="active" :to="item.to" :tariff="tariff">
            {{ item.title }}
          </router-link>
        </template>
      </tabs>
      <!--      <div :class="{ visible: true }" class="stack-info__create">-->
      <!--        <base-button class="stack-info__create-btn" @click="newRule()">-->
      <!--          {{ $t('newRule') }}-->
      <!--        </base-button>-->
      <!--      </div>-->
    </div>
    <transition name="bubble">
      <router-view :tariff="tariff"></router-view>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs';

export default {
  name: 'ContainerServiceNav',
  components: {
    tabs,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      navRaw: [
        {
          title: this.$t('nav.storage'),
          to: { name: 'ServiceType' },
        },
        {
          title: this.$t('nav.balancer'),
          to: { name: 'viewBalancer' },
        },
      ],
    };
  },
  computed: {
    nav() {
      return this.navRaw.filter(
        i => !i.access || (i.access && this[i.access.name] === i.access.value)
      );
    },
  },
  beforeRouteLeave(_to, _from, next) {
    this.reset();
    next();
  },
  methods: {
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.tariff.id);
    },
    reset() {
      this.$store.dispatch('moduleStack/resetBalancer');
      this.$store.dispatch('moduleStack/resetStorage');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "newRule": "Добавить балансировщик",
    "title": {
      "server": "Сети",
      "plan": "Тарифный план",
      "stats": "Статистика"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "nav": {
      "balancer": "Балансировщики",
      "storage": "Хранилище S3",
      "networkext": "Публичные подсети",
      "router": "Роутеры",
      "firewall": "Файрвол"
    },
    "newServer": "Новая сеть",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      //flexy(space-betweeb);
      flexy(space-between, center);
      //flex-direction: column;
    }

    //&__content {
    //  flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        flex: 1 1 auto;
      //}
    }
  }
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
